:root {
  --limegreen: hsl(163, 72%, 41%);
  --brightred: hsl(356, 69%, 56%);
  --facebook: hsl(208, 92%, 53%);
  --twitter: hsl(203, 89%, 53%);
  --instagram-start: hsl(37, 97%, 70%);
  --instagram-middle: hsl(5, 77%, 71%);
  --instagram-end: hsl(329, 70%, 58%);
  --youtube: hsl(348, 97%, 39%);
  --toggle-bg-light: hsl(230, 22%, 74%);
  --toggle-bg-start: hsl(210, 78%, 56%);
  --toggle-bg-end: hsl(146, 68%, 55%);
  --toggle-light: hsl(230, 19%, 60%);
  --toggle-button-light: hsl(228, 46%, 96%);
  --dark-bg: hsl(230, 17%, 14%);
  --dark-top-bg: hsl(232, 19%, 15%);
  --dark-card: hsl(228, 28%, 20%);
  --dark-card-hover: hsl(228, 25%, 27%);
  --dark-text1: hsl(228, 34%, 66%);
  --dark-text2: hsl(0, 0%, 100%);
  --light-bg: hsl(0, 0%, 100%);
  --light-top-bg: hsl(225, 100%, 98%);
  --light-card: hsl(227, 47%, 96%);
  --light-card-hover: hsl(228, 33%, 91%);
  --light-text1: hsl(230, 12%, 44%);
  --light-text2: hsl(230, 17%, 14%);
  --background: var(--light-bg);
  --text-color: var(--light-text2);
  --text-color2: var(--light-text1);
  --card-bg: var(--light-card);
  --card-hover: var(--light-card-hover);
  --toggle: var(--toggle-light);
  --toggle-bg: var(--toggle-bg-light);
  --toggle-button: var(--toggle-button-light);
}

body.dark {
  --background: var(--dark-bg);
  --text-color: var(--dark-text2);
  --text-color2: var(--dark-text1);
  --card-bg: var(--dark-card);
  --card-hover: var(--dark-card-hover);
  --toggle: var(--light-bg);
  --toggle-bg: linear-gradient(
    225deg,
    var(--toggle-bg-end) 0%,
    var(--toggle-bg-start) 98.02%
  );
  --toggle-button: var(--dark-bg);
}

:root {
  --font-inter: "Inter", sans-serif;
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-inter);
  background: var(--background);
  color: var(--text-color);
}

.visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

h1,
h2,
h3 {
  margin-top: 0;
  line-height: 1.1;
}

h1 {
  font-size: 1.5rem;
  margin-bottom: 0.1875rem;
}
@media (min-width: 71.875em) {
  h1 {
    font-size: 1.75rem;
  }
}

a,
a:visited,
a:active {
  text-decoration: none;
}

.container {
  padding: 0 1.5625rem;
  max-width: 69.375rem;
  margin: 0 auto 2.875rem;
}
@media (min-width: 71.875em) {
  .container {
    padding: 0;
  }
}

.header {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2.25rem;
}
@media (min-width: 40em) {
  .header {
    justify-content: space-between;
    align-items: center;
  }
}
.header__title {
  width: 100%;
}
@media (min-width: 40em) {
  .header__title {
    width: auto;
  }
}
.header__subtitle {
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--text-color2);
}

.cards {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem;
}
@media (min-width: 40em) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 71.875em) {
  .cards {
    grid-template-columns: repeat(4, 1fr);
  }
}

.card {
  position: relative;
  overflow: hidden;
  background: var(--card-bg);
  color: var(--dark-text1);
  padding: 1.5625rem;
  border-radius: 0.3125rem;
  text-align: center;
  transition: background 150ms ease-in-out;
  cursor: pointer;
}
.card:hover {
  background: var(--card-hover);
}
.card--facebook {
  border-top: 0.3125rem solid var(--facebook);
}
.card--twitter {
  border-top: 0.3125rem solid var(--twitter);
}
.card--instagram {
  padding-top: 1.875rem;
}
.card--instagram::before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.3125rem;
  background: linear-gradient(225deg, var(--instagram-end), var(--instagram-middle) 50.91%, var(--instagram-start) 100%);
}
.card--youtube {
  border-top: 0.3125rem solid var(--youtube);
}
.card__platform {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.25rem;
  margin-top: 0.3125rem;
  margin-bottom: 1.75rem;
}
.card__subtitle {
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--text-color2);
}
.card__icon {
  margin-right: 0.5rem;
}
.card__username {
  font-size: 0.75rem;
  font-weight: 700;
  color: var(--text-color2);
}
.card__followers {
  margin-bottom: 1.5625rem;
}
.card__count {
  color: var(--text-color);
  font-weight: 700;
  letter-spacing: -0.125rem;
  line-height: 1;
  margin-bottom: 0.25rem;
}
.card__count--big {
  font-size: 3.5rem;
}
.card__count--small {
  font-size: 2rem;
}
.card__label {
  font-size: 0.75rem;
  letter-spacing: 0.3125rem;
  font-weight: 400;
  color: var(--text-color2);
  text-transform: uppercase;
}
.card__change {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 700;
}
.card__change--up {
  color: var(--limegreen);
}
.card__change--down {
  color: var(--brightred);
}
.card__change img {
  margin-right: 0.25rem;
}
.cardGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  justify-items: start;
  gap: 1.4375rem;
}
.cardGrid .card__count {
  margin-bottom: 0;
}
.cardGrid .card__count,
.cardGrid .card__change {
  align-self: end;
}
.cardGrid img,
.cardGrid .card__change {
  justify-self: end;
}

body {
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.theme {
  display: flex;
  justify-content: center;
  align-items: center;
}

.theme h4 {
  color: var(--light-text1);
  padding-right: 0.625rem;
}

.dark-mode {
  --background: var(--dark-bg);
  --text-color: var(--dark-text2);
  --text-color2: var(--dark-text1);
  --card-bg: var(--dark-card);
  --card-hover: var(--dark-card-hover);
}

.toggle-btn {
  position: relative;
  display: flex;
  align-items: center;
  width: 3.125rem;
  height: 1.6875rem;
  border-radius: 0.875rem;
  background-color: var(--toggle-bg-light);
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: none;
}

.toggle-span {
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  left: 1.5625rem;
  border-radius: 50%;
  outline: none;
  box-shadow: none;
  background-color: var(--light-bg);
  transition: left 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.dark-mode .theme h4 {
  color: var(--dark-text1);
}

.dark-mode .toggle-btn {
  background: linear-gradient(225deg, var(--toggle-bg-end) 0%, var(--toggle-bg-start) 98.02%);
}

.dark-mode .toggle-span {
  left: 0.1875rem;
  background-color: var(--dark-bg);
}